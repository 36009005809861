.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* src/index.css or src/App.css */
html {
  width: 100%;
  height: 100%;

  background: #11e8bb; /* Old browsers */
  background: -moz-linear-gradient(top, #11e8bb 0%, #8200c9 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #11e8bb 0%, #8200c9 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #11e8bb 0%, #8200c9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#11e8bb', endColorstr='#8200c9', GradientType=0); /* IE6-9 */
  overflow: hidden;
}

body {
  margin: 0;
  padding: 0;
} 
.logo {
  margin-top: -40px;
  padding-top: 100px;
  scale: 1.7;
} 



.elfsight-widget-container {
  position: fixed; /* Fixed positioning to stay in view */
  bottom: 20px; /* Adjust this value to control vertical position */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center the widget */
  z-index: 10; /* Ensure it's above ThreeScene */
  background: rgba(0, 0, 0, 0.5); /* Optional: add a semi-transparent background to enhance visibility */
  padding: 10px; /* Optional: add some padding around the widget */
  border-radius: 8px; /* Optional: add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Optional: add shadow for better contrast */
}
