/* src/ThreeScene.css */
.scene-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #0d0d0d; /* Dark background color */
}

#canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Canvas stays behind the text and logo */
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2; /* Overlay content stays on top of the canvas */
  color: white;
}

.logo {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}

h1 {
  font-size: 2em;
  margin: 0;
}
